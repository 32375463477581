<template>
	<div id="accordion">
		<div class="">
			<div class="card-header cursor-pointer" v-on:click="openTab">
				<h5 class="mb-0 ">
					<p data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
						{{ data.question }}
					</p>
				</h5>
				<div class="pos-icon"><i
					:class="{'el-icon-minus': checked === true ,'el-icon-plus': checked === false}"></i></div>
			</div>
			<div class="collapse" :class="{'show': checked === true}" data-parent="#accordion">
				<el-card shadow="never">
					<p v-html="data.answer"></p>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AccordionComponent',
	props: {
		data: {
			type: Object,
			required: true
		},
		id: {
			type: Number,
			required: true
		},
	},
	data: function () {
		return {
			checked: false
		}
	},
	methods: {
		openTab: function () {
			this.checked = !this.checked
		}
	}
}
</script>

<style scoped>

.pos-icon {
	float: right;
	position: absolute;
	right: 10px;
}
</style>
