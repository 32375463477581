<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row">
				<div class="col-lg-9 col-md-9 col-sm-12">
					<div class="row no-gutters space-y-4 lg:space-y-0">
						<div class="col-6">
							<div>
								<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer ml-3">
									<span data-cy='article-heading'
										  class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
											$t('faqzFullName')
										}}</span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<el-input style="float: right !important;" class="w-5/6"
									  v-bind:placeholder="$t('search')" @input="onSearch" v-model="searchName">
								<i slot="prefix" class="el-input__icon el-icon-search "></i>
							</el-input>

						</div>
					</div>
					<div class="row no-gutters space-y-4 lg:space-y-0 mt-3 overflow-y-scroll"
						 v-infinite-scroll="loadMore">
						<div class="col-md-12 col-lg-12" v-for="(item,key) in GET_FAQ.data" v-bind:key="key">
							<AccordionComponent :data="item" :id="key"/>
						</div>
						<div class="col-md-12">
							<p class="mb-3 mt-1 flex justify-center space-x-3 text-center text-gray-500"
							   v-if="loadingFaqz">
                                <span>
                                    <svg class="animate-spin mr-2 h-5 w-5 text-gray-400"
										 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
								Loading...
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-md-3 col-sm-12">
					<div class="bg-white border rounded-md p-1">
						<div class="flex flex-col space-y-5 p-3 rounded-md">
							<span class="font-semibold text-lg text-gray-900">{{ $t('category') }}</span>
							<div class="flex flex-col">

								<ul class="space-y-2" v-if="GET_FAQ_CATEGORY.data">
									<li class="flex align-items-center space-x-4 cursor-pointer"
										v-on:click="filterCategory(item.id)"
										v-for="(item,key) in GET_FAQ_CATEGORY.data" :key="key">
										<span class="text-gray-900">{{ item.title }}</span>
									</li>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import debounce from 'lodash.debounce'
import {mapGetters} from 'vuex'
import AccordionComponent from './component/AccordionComponent'

export default {
	name: 'Faqz',
	components: {AccordionComponent},
	methods: {
		getFaqs: function () {
			let pageUrl = `?limit=${this.limit}&company_id=${this.company_id}`
			this.$store.dispatch('customer/getFaqz', {url: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getFaqCategory: function () {
			let pageUrl = `?company_id=${this.company_id}`
			this.$store.dispatch('customer/getFaqCategory', {data: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		loadMore: function () {
			if (this.GET_FAQ.current_page < this.GET_FAQ.total_pages) {
				this.loadingFaqz = true
				let pageUrl = `?page=${this.GET_FAQ.current_page + 1}&limit=${this.limit}&company_id=${this.company_id}`
				if (this.categoryName !== '') {
					pageUrl = pageUrl + `&category__id=${this.categoryName}`
				}
				if (this.searchName !== '') {
					pageUrl = pageUrl + `&search=${this.searchName}`
				}
				this.$store.dispatch('customer/getFaqz', {url: pageUrl})
					.then(_ => {
						this.loadingFaqz = false
					}).catch((err) => {
					this.loadingFaqz = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
			}
		},
		toggleMore(status) {
			this.more = status
		},
		filterCategory(name) {
			this.categoryName = name
			let pageUrl = `?limit=${this.limit}&company_id=${this.company_id}`
			if (this.categoryName !== '') {
				pageUrl = pageUrl + `&category__id=${this.categoryName}`
			}
			this.$store.dispatch('customer/getFaqz', {url: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		onSearch: debounce(function () {

			let pageUrl = `?limit=${this.limit}&company_id=${this.company_id}`
			if (this.searchName !== '') {
				pageUrl = pageUrl + `&search=${this.searchName}`
			}
			if (this.categoryName !== '') {
				pageUrl = pageUrl + `&category__id=${this.categoryName}`
			}
			this.$store.dispatch('customer/getFaqz', {url: pageUrl}).catch(error => {
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		}, 200)
	},
	mounted() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
		this.company_id = this.$route.params.company_id
		this.getFaqs()
		this.getFaqCategory()

	},
	data: function () {
		return {
			limit: 30,
			searchName: '',
			categoryName: '',
			loadingFaqz: false,
			more: false,
			systemMode: '',
			company_id: null
		}
	},
	computed: {
		...mapGetters({
			GET_FAQ: 'customer/GET_FAQ',
			GET_FAQ_CATEGORY: 'customer/GET_FAQ_CATEGORY'
		})
	},
}
</script>

<style scoped>

</style>
